import React, {useState} from "react";
import validator from 'validator';

import {
  Box,
  Button,
  Grid,
  Step, StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from "@mui/material";
import {useForm} from "react-hook-form";



import zodiac from 'zodiac-signs'

import {ControlledAutocomplete} from "./ControlledComponents";
import {useSnackbar} from "notistack";
import {envConfig} from "../envConfig";

export default function CustomPropsForm(props) {

  // console.log(zodiac("en-US").getSignByDate({ day: 35, month: 9 }));

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(100), (v, idx) => (currentYear - idx).toString());


  const [days,setDays] =
      useState(Array.from(new Array(31), (v, idx) => (1 + idx).toString()))


  const [zodiacObj, setZodiacObj] = useState({})

  const [error,setError] = useState(false)




  const {register,control,getValues,formState: {isSubmitting, dirtyFields},} = useForm();


  const onDateBlur = ()=>{
    if(dirtyFields.month && dirtyFields.year){
      setDays(
          Array.from(new Array( new Date(getValues("year"), getValues("month"), 0).getDate()), (v, idx) => (1 + idx).toString())
      )
    }
    if(dirtyFields.day && dirtyFields.month){
      setZodiacObj(zodiac("en-US").getSignByDate({ day: getValues("day"), month: getValues("month") }))
    }
    if(dirtyFields.month && dirtyFields.day && dirtyFields.year){
      //validate date
      if (validator.isDate(getValues("month")+"-"+getValues("day")+"-"+getValues("year"))){
        setError(true)
      } else{
        handleNext()
        setError(false)
      }
    }
  }


  const steps = [
    {
      label: "Step 1",
      fieldNames: ["firstname","lastname"],
      description: "Enter in your first and last name!",
      component:
          <Grid container spacing={3} display={"flex"} sx={{maxWidth:"100%"}}>
            <Grid item lg={6} xs={6} p={1}>
              <TextField sx={{maxWidth: "200px"}} label={"First Name"} inputProps={{
                ...register("firstname")
              }} required/>
            </Grid>
            <Grid item lg={6} xs={6}>
              <TextField sx={{maxWidth: "200px"}} label={"Last Name"} inputProps={{
                ...register("lastname")
              }} required/>
            </Grid>
          </Grid>
    },
    {
      label: "Step 2",
      fieldNames: ["month", "day", "year"],
      description: "Tell us your birthday. We want to celebrate it together! 🎂🥳",
      component:
      <Grid container gap={2}>
        <Grid item lg={3} xs={3}>

          <ControlledAutocomplete
              name={"month"}
              control={control}
              autoCompleteOnBlur={onDateBlur}
              options={["1","2","3","4","5","6","7","8","9","10","11","12"]}
              label={"MM"}
              autoComplete={"months"}
              required
          />
        </Grid>
        <Grid item lg={3} xs={3}>
          <ControlledAutocomplete
              name={"day"}
              control={control}
              error={error}
              autoCompleteOnBlur={onDateBlur}
              options={days}
              label={"DD"}
              autoComplete={"day"}
              required
          />
        </Grid>
          <Grid item lg={3} xs={4}>
           <ControlledAutocomplete
               name={"year"}
               autoCompleteOnBlur={onDateBlur}
               control={control}
               options={years}
               label={"YYYY"}
               autoComplete={"year"}
               required
           />
          </Grid>
        <Typography alignItems={"center"} fontSize={"2rem"}>{zodiacObj?.symbol?.toString()}</Typography>

      </Grid>
    },
  ]



  const handleNext = () => {
    //validates and submits to db

    let data = {}

    for(const field of steps[activeStep].fieldNames){
      if(!getValues(field)) {
        enqueueSnackbar("Please fill out the missing fields 👀", {
          variant: 'error'
        })
        return;
      }
      data[field] = getValues(field)
    }

    // console.log({...data})
    //shoot to backend
    fetch(envConfig.cloudflareWorkerConfirmEmail,{body : JSON.stringify({
        email : props.email,
        properties : {
          ...data
        }
      }),
    method : "POST"
    }).then(async (response) => {
      console.log(await response.text())
    })

    if (activeStep < steps.length) {
      setActiveStep(activeStep + 1)
    }

  }
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  const [activeStep, setActiveStep] = useState(0)
  return (<>
    <Grid container display={"flex"} justifyContent={"center"}
          alignItems={"center"}>
      <Grid item lg={12} sx={12} sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <Stepper activeStep={activeStep} orientation="vertical" >
          {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                    sx={{
                      mt : "1rem",
                      mb : "1rem"
                    }}
                    optional={
                      index === 2 ? (
                          <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                >
                  {step.label}
                </StepLabel>
                <StepContent sx={{ mt: "1rem", mb: "1rem"}}>
                  <Typography sx={{pb:"1rem"}}>{step.description}</Typography>
                  {step.component}
                  <Box sx={{mb: 2}}>
                    <div>
                      <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{mt: 1, mr: 1}}
                      >
                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                      </Button>
                      <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{mt: 1, mr: 1}}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
          ))}
        </Stepper>
      </Grid>
      {activeStep === steps.length && <Grid item lg={12} xs={12}>
        <Typography fontSize={"2rem"} textAlign={"center"}>
          You're all set! Welcome aboard 👀
        </Typography>
      </Grid>}
    </Grid>
  </>)
}
