import {Autocomplete, TextField} from "@mui/material";
import {Controller} from "react-hook-form";


export const ControlledAutocomplete = (props) => {
  const {
    name,
    options,
    autoCompleteOnBlur,
    control,
    multiple,
    required,
    disabled,
    error,
    freeSolo,
    autoComplete,
    autoCompleteProps,
    ...otherProps
  } = props;
  return (
      <Controller
          control={control}
          defaultValue={multiple ? [] : null}
          name={name}
          render={
            ({field: {onChange, value,
            ...props}}) => (
              <Autocomplete
                  {...props}
                  {...autoCompleteProps}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  onBlur={autoCompleteOnBlur}
                  options={options}
                  disabled={disabled}
                  disableClearable
                  multiple={multiple}
                  value={value ?? (multiple ? [] : null)}
                  renderInput={(params) =>
                      <TextField
                          {...params}
                          {...otherProps}
                          error={error}
                          required={required ?
                              (multiple ? !(value?.length > 0) : true)
                              : false}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoComplete,
                          }}
                      />
                  }
              />
          )}
      />
  );
}
