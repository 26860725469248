import logo from './logo.svg';
import './App.css';
import React from "react";

import Home from "./pages/Home";

function App() {
  return (
    <div className="App">
     <Home/>
    </div>
  );
}

export default App;
