import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Button, Grid, Typography} from "@mui/material";
import CustomPropsForm from "../components/CustomPropsForm";
import {envConfig} from "../envConfig";
import {useSnackbar} from "notistack";

export default function Home(props) {

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const [email, setUserEmail] = useState(null)
  const [response, setResponse] = useState(null)

  useEffect(() => {
    //get email and confirm it
    submitEmail()
  },[])


  const submitEmail = ()=>{

    const userEmail = new URLSearchParams(window.location.search).get("email")
    setUserEmail(userEmail)
    if (!userEmail) {
      if (process.env.NODE_ENV === "development") {
        enqueueSnackbar("No email. In production this will redirect",
            {variant: "info"})
      } else {
        window.location.href = "https://kbrief.me?utm_source=confirm.kbrief.me&reason=no_email"
      }
    }

    fetch(envConfig.cloudflareWorkerConfirmEmail,
        {method: "POST", body: JSON.stringify({email: userEmail})}).then(
        async (response) => {
          setResponse(response)
          console.log(response)
        })

  }

  return (
      <div>
        <Grid container display={"flex"} alignItems={"center"}
              justifyContent={"center"} p={2} spacing={2}>
          <Grid item xs={12} xl={12} textAlign={"center"}>
            <a href={"/"}><img src={"/kbriefLogo.svg"}
                               height={"40"}/></a>

          </Grid>
          <Grid item lg={10} xs={10}>
            {response && response.status === 200? <Alert severity="success">
              <AlertTitle>Your subscription has been confirmed</AlertTitle>
            </Alert>:
            <Alert severity="error"
                   action={
                     <Button color="inherit" size="small" onClick={submitEmail}>
                       Try Again
                     </Button>
                   }>
              <AlertTitle>Something went wrong 🥺 </AlertTitle>
            </Alert>}
          </Grid>
          <Grid item lg={12} xs={12}>
            <Typography textAlign={"center"} variant={"h1"} fontSize={"2rem"}>We
              want know more about <span style={{color: "#2403fc"}}>you!</span>
            </Typography>
          </Grid>

          <Grid item lg={12} xs={12}>
            <CustomPropsForm email={email}/>
          </Grid>
        </Grid>
      </div>
  )
}
